import { 
    RequestFieldBadge, 
    RequestDiffFieldBadge, 
    RequestStepper, 
    RequestTimeline, 
    RequestDiffAffiliateBadge, 
    RequestDiffCompanyBadge,
    RequestDiffUserGroupBadge
} from "@/components";
import { FieldClassifications, Roles, RequestStatuses, DiffBadgeNature} from "@/enums";
import { 
    IField, 
    IRequest, 
    ISnakeApplication, 
    ISnakeApplicationEntity, 
    IAffiliate, 
    ICompany, 
    IDiffBadge, 
    IGroup, 
    IEntityRegion } from "@/models";
import { Vue, Component } from "vue-property-decorator";

@Component({
    name: "requestView",
    metaInfo: {
        title: "Request",
    },
    components: {
        RequestFieldBadge,
        RequestDiffFieldBadge,
        RequestDiffAffiliateBadge,
        RequestDiffCompanyBadge,
        RequestDiffUserGroupBadge,
        RequestStepper,
        RequestTimeline,
    },
})
export default class RequestView extends Vue {

    public loading = false;

    public requestId: string = this.$route.params.id;

    public readMore: boolean = false;

    get canEditRequest(): boolean {
        const user = this.$store.getters["account/user"];
        return user.roles.includes(Roles.Administrator) ||
            this.request.requestedBy.toLowerCase() == user.email.toLowerCase() ||
            (this.snakeApp.general.owners.length > 0 && this.snakeApp.general.owners.filter(o => o.toLowerCase() == user.email.toLowerCase()).length > 0); 
    }

    get request(): IRequest {
        return this.$store.getters["app/currentRequest"];
    }

    get snakeApp(): ISnakeApplication {
        return this.$store.getters["app/currentSnakeApp"];
    }

    get appEntities(): IEntityRegion[] {
        return this.$store.getters["app/snakeGroupedEntities"];
    }

    get reducedAppEntities(): IEntityRegion[] {
        let reduced: IEntityRegion[] = [];
        if(this.appEntities != null && this.appEntities.length != 0){
            reduced.push({region: this.appEntities[0].region, entities: this.appEntities[0].entities.slice(0, 3)});
        }
        return reduced;
    }

    get showAllEntities(): boolean {
        const lengths = this.appEntities.map(ge => this.computeTotalLength(ge.entities));
        const totalLengths  = this.sum(lengths);
        return totalLengths < 90;
    }

    private computeTotalLength(entities: ISnakeApplicationEntity[]): number{
        const lengths = entities.map(e => e.name.length);
        const totalLengths  = this.sum(lengths);
        return totalLengths;
    }

    private sum(lengths: number[]): number{
        return lengths.reduce((sum, current) => sum + current, 0); 
    }

    async mounted(): Promise<void> {
        this.loading = true;
        try {
            await this.$store.dispatch("app/loadRequestById", this.requestId);
            await this.$store.dispatch("app/loadSnakeAppById", this.request.snakeId);
            await this.$store.dispatch("app/loadSnakeGroupedEntities", this.snakeApp.general.entities);
        }
        catch (error) {
            this.$emit("error", error);
        }
        this.loading = false;
    }

    public snakeApplicationLogo(): string {
        const imageString = this.snakeApp.general.logo;
        if(imageString) {
            return imageString;
        }
        else {
            return require("@/assets/images/snake-v2-blue.svg");
        }
    }

    public successMessage(message: string): void {
        this.$emit("success", message);
    }

    public errorMessage(message: string): void {
        this.$emit("error", message);
    }

    public getEntitiesHtml(entities: ISnakeApplicationEntity[]): string {
        return entities?.map(e => { return e.name }).join(', ');
    }

    //!- SCOPE AFFILIATES

    get scopeAffiliates(): IAffiliate[] {
        if(this.request != null) {
            return (this.request.status === RequestStatuses.Closed) 
                 ? this.request.scopeAffiliates
                 : this.requestClientEditAffiliates;
        } else {
            return null;
        }
    }

    get requestClientEditAffiliates(): IAffiliate[] {
        if(this.request != null) {            
            const currentRequestAffiliates = this.request.scopeAffiliates;
            const clientAffiliates = this.request.client == null ? [] : this.request.client.scopeAffiliates;
            return this.intersection<IAffiliate>(currentRequestAffiliates, clientAffiliates);
        } else {
            return null;
        }
    }

    get requestDifferenceAffiliates(): IDiffBadge<IAffiliate>[] {
        if(this.request != null) {
            const approvedAffiliates = this.request.client == null ? [] : this.request.client.scopeAffiliates;
            const currentAffiliates = this.request.scopeAffiliates;
            const addedAffiliates = this.computeScopeDifference<IAffiliate>(
                approvedAffiliates, 
                currentAffiliates, 
                this.request.client == null ? DiffBadgeNature.New : DiffBadgeNature.Added);
            const removedAffiliates = this.computeScopeDifference<IAffiliate>(
                approvedAffiliates, 
                currentAffiliates, 
                DiffBadgeNature.Removed);
            return (!addedAffiliates) ? removedAffiliates : addedAffiliates.concat(removedAffiliates);
        } else {
            return null;
        }
    }

    //!- SCOPE COMPANIES

    get scopeCompanies(): ICompany[] {
        if(this.request != null) {
            return this.request.status === RequestStatuses.Closed
                ? this.request.scopeCompanies
                : this.requestClientEditCompanies;
        } else {
            return null;
        }
    }

    get requestClientEditCompanies(): ICompany[] {
        if(this.request != null) {
            const currentRequestCompanies = this.request.scopeCompanies;
            const clientCompanies = this.request.client == null ? [] : this.request.client.scopeCompanies;
            return this.intersection<ICompany>(currentRequestCompanies, clientCompanies);
        } else {
            return null;
        }
    }

    get requestDifferenceCompanies(): IDiffBadge<ICompany>[] {
        if(this.request != null) {
            const approvedCompanies = this.request.client == null ? [] : this.request.client.scopeCompanies;
            const currentCompanies = this.request.scopeCompanies;
            const addedCompanies = this.computeScopeDifference<ICompany>(
                approvedCompanies, 
                currentCompanies, 
                this.request.client == null ? DiffBadgeNature.New : DiffBadgeNature.Added);
            const removedCompanies = this.computeScopeDifference<ICompany>(
                approvedCompanies, 
                currentCompanies, 
                DiffBadgeNature.Removed);
            return (!addedCompanies) ? removedCompanies : addedCompanies.concat(removedCompanies);
        } else {
            return null;
        }
    }

    //!- Groups

    get scopeUserGroups(): IGroup[] {
        if(this.request != null) {
            return this.request.status === RequestStatuses.Closed
                ? this.request.scopeGroups
                : this.requestClientEditUserGroups;
        } else {
            return null;
        }
    }

    get requestClientEditUserGroups(): IGroup[] {
        if(this.request != null) {
            const currentRequestUserGroups = this.request.scopeGroups;
            const clientUserGroups = this.request.client == null ? [] : this.request.client.scopeGroups;
            return this.intersection<IGroup>(currentRequestUserGroups, clientUserGroups);
        } else {
            return null;
        }
    }

    get requestDifferenceUserGroups(): IDiffBadge<IGroup>[] {
        if(this.request != null) {
            const approvedUserGroups = this.request.client == null ? [] : this.request.client.scopeGroups;
            const currentUserGroups = this.request.scopeGroups;
            const addedUserGroups = this.computeScopeDifference<IGroup>(
                approvedUserGroups, 
                currentUserGroups, 
                this.request.client == null ? DiffBadgeNature.New : DiffBadgeNature.Added);
            const removedUserGroups = this.computeScopeDifference<IGroup>(
                approvedUserGroups, 
                currentUserGroups, 
                DiffBadgeNature.Removed);
            return (!addedUserGroups) ? removedUserGroups : addedUserGroups.concat(removedUserGroups);
        } else {
            return null;
        }
    }

    //!- FILEDS

    get requestGreenFields(): IField[] {
        return this.computeRequestFields(FieldClassifications.Green);
    }

    get requestGreenDifferenceFields(): IDiffBadge<IField>[] {
        return this.computeRequestDifferenceFields(FieldClassifications.Green);
    }

    get requestOrangeFields(): IField[] {
        return this.computeRequestFields(FieldClassifications.Orange);
    }

    get requestOrangeDifferenceFields(): IDiffBadge<IField>[] {
        return this.computeRequestDifferenceFields(FieldClassifications.Orange);
    }

    get requestRedFields(): IField[] {
        return this.computeRequestFields(FieldClassifications.Red);
    }

    get requestRedDifferenceFields(): IDiffBadge<IField>[] {
        return this.computeRequestDifferenceFields(FieldClassifications.Red);
    }

    get requestNewFields(): IField[] {
        return this.computeRequestFields(FieldClassifications.NewField);
    }

    get requestNewFieldDifferenceFields(): IDiffBadge<IField>[] {
        return this.computeRequestDifferenceFields(FieldClassifications.NewField);
    }

    get requestClientEditFields(): IField[] {
        //!- Called only if the request is not closed
        if(this.request != null) {
            const currentRequestFields = this.request.fields;
            const clientFields = this.request.client == null ? [] : this.request.client.fields;
            return this.intersection<IField>(currentRequestFields, clientFields);
        } else {
            return null;
        }
    }

    //!- PRIVATE 

    private computeRequestFields(classification: FieldClassifications): IField[] {
        if(this.request != null) {
            const request = this.request;
            const fields = (request.status === RequestStatuses.Closed)
                ? this.request.fields
                : this.requestClientEditFields;
            return fields == null ? [] : fields.filter(f => f.classification === classification);
        } else {
            return null;
        }
    }

    private computeRequestDifferenceFields(classification: FieldClassifications): IDiffBadge<IField>[] {
        if(this.request != null) {
            const approvedFields = this.request.client == null ? [] : this.request.client.fields?.filter(f => f.classification === classification);
            const currentFields = this.request.fields?.filter(f => f.classification === classification);
            const addedFields = this.computeScopeDifference<IField>(
                approvedFields, 
                currentFields, 
                this.request.client == null ? DiffBadgeNature.New : DiffBadgeNature.Added);
            const removedFields = this.computeScopeDifference<IField>(
                approvedFields, 
                currentFields, 
                DiffBadgeNature.Removed);
            return (!addedFields) ? removedFields : addedFields.concat(removedFields);
        } else {
            return null;
        }
    }

    //!- Returns the fields that have been added(if *forAddedAffiliates* = TRUE)/removed(otherwise)
    private computeScopeDifference<T extends { id: string }>(scopeApproved: T[], scopeCurrent: T[], nature: DiffBadgeNature): IDiffBadge<T>[] {
        if(this.request != null) {
            if(this.request.status === RequestStatuses.Closed)
                return null;
            else{
                const wrappedApproved: IDiffBadge<T>[] = [];
                if(scopeApproved != null){
                    scopeApproved.forEach(scope => {
                        const d = { id: scope.id, nature: nature, innerObject: scope };
                        wrappedApproved.push(d);
                    });
                }
                
                const wrappedCurrent: IDiffBadge<T>[] = [];
                if(scopeCurrent){
                    scopeCurrent.forEach(scope => {
                        const d = { id: scope.id, nature: nature, innerObject: scope };
                        wrappedCurrent.push(d);
                    });
                }
                const diff = nature === DiffBadgeNature.Added ||  nature === DiffBadgeNature.New
                    ? this.difference<T>(wrappedCurrent, wrappedApproved)
                    : this.difference<T>(wrappedApproved, wrappedCurrent);
                
                return diff;
            }
        } else {
            return null;
        }
    }

    //!- Computes the intersection of two lists
    private intersection<T extends { id: string }>(list1: T[], list2: T[]): T[] {
        return (list1 == null || list2 == null) 
            ? []
            : list1.filter(f1 => list2.some(f2 => f1.id === f2.id));
    }

    //!- Computes the difference between two lists
    //!- Returns the elements in *list1* missing in *list2*
    private difference<T>(list1: IDiffBadge<T>[], list2: IDiffBadge<T>[]): IDiffBadge<T>[] {
        return list1.filter(f1 => !list2.some(f2 => f1.id === f2.id));
    }
}
